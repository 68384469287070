import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import $ from 'jquery'

import project1 from '../assets/images/projects/3.png'
import project2 from '../assets/images/projects/23.png'
import project3 from '../assets/images/projects/19.png'
import project4 from '../assets/images/projects/24.png'
import project5 from '../assets/images/projects/13.png'
import project6 from '../assets/images/projects/22.png'
import project7 from '../assets/images/projects/15.png'
import project8 from '../assets/images/projects/25.png'
import project9 from '../assets/images/projects/16.png'
import project10 from '../assets/images/projects/21.png'
import project11 from '../assets/images/projects/2.png'
import project12 from '../assets/images/projects/4.png'



class Work extends React.Component {
  componentDidMount() {
    function remove(arr, item) {
      for (var i = arr.length; i--; ) {
        if (arr[i] === item) {
          arr.splice(i, 1)
        }
      }
    }

    $('#work-tags li a').on('click', function(e) {
      e.preventDefault()
      $('#work-tags li a.all.active').removeClass('active')

      var category = $(this)
        .attr('class')
        .split(/\s+/)[1]

      if (category === 'all') {
        $('#work-tags li a').each(function() {
          if ($(this).hasClass('active')) {
            $(this).removeClass('active')
          }
        })
        $('#work-tags li a.all').addClass('active')
      } else {
        if ($(this).hasClass('active')) {
          $(this).removeClass('active')
        } else {
          $(this).addClass('active')
        }
      }
      if ($('#work-tags li a.active').length === 0) {
        $('#work-tags li a.all').addClass('active')
      }

      var active_category_list = []
      $('#work-tags li a.active').each(function() {
        var category = $(this)
          .attr('class')
          .split(/\s+/)[1]
        if (active_category_list.includes(category)) {
          remove(active_category_list, category)
        } else {
          active_category_list.push(category)
        }
      })
      $('.card-wrapper').hide('slow')
      active_category_list.forEach(function(item, index, arr) {
        if (item === 'all') {
          $('.card-wrapper').show('slow')
        } else {
          $('.card-wrapper.' + item).show('slow')
        }
      })
    })
  }

  render() {
    return (
      <Layout>
        <Helmet
          title="All Work | Portfolio Sagar Satam"
          meta={[
            { name: 'Design', content: 'Portfolio' },
            { name: 'Design', content: 'product design, designer' },
          ]}
        />

        <div id="main">
          <section id="title-section" className="title-block inner">
            <div className="hp-featured">
              <h1 className="hp-featured__title">
                all my work
              </h1>
              <div className="hp-featured__content">
                {/* <div className="hp-featured__hr-line" /> */}
                <h5 className="hp-featured__text">
                  As a designer I have worked on various types of design
                  projects, here are some of my works.
                </h5>
                <p className="hp-featured__note">
                  *Due to confidentiality & NDA reasons, of all the projects
                  that i have worked on during my professional work experience,
                  only a selected few projects are shown on this website.{' '}
                  {/* <a href="/say-hello">
                    <u>Get in touch</u>
                  </a>{' '}
                  with me for more details related to all my projects. */}
                </p>
              </div>
            </div>
          </section>
          
          
          <section id="work-tags" className="inner">
            <ul>
              <li>
                <a href="/" className="tags all active">
                  /all
                </a>
              </li>
              <li>
                <a href="/" className="tags featured">
                  /featured
                </a>
              </li>
              <li>
                <a href="/" className="tags ai-enabled">
                  /ai-enabled
                </a>
              </li>
              <li>
                <a href="/" className="tags service-design">
                  /service design
                </a>
              </li>
              <li>
                <a href="/" className="tags research">
                  /research
                </a>
              </li>
              <li>
                <a href="/" className="tags product-design">
                  /product design
                </a>
              </li>
              <li>
                <a href="/" className="tags user-experience">
                  /user experience
                </a>
              </li>
              <li>
                <a href="/" className="tags ux-strategy">
                  /ux strategy
                </a>
              </li>
              <li>
                <a href="/" className="tags digital-ux">
                  /digital ux
                </a>
              </li>
              <li>
                <a href="/" className="tags digital-ui">
                  /digital ui
                </a>
              </li>
              {/* <li>
                <a href="/" className="tags interaction-design">
                  /interaction design
                </a>
              </li> */}
              {/* <li>
                <a href="/" className="tags business-design">
                  /business design
                </a>
              </li> */}
              <li>
                <a href="/" className="tags branding">
                  /brand identity
                </a>
              </li>
            </ul>
          </section>

          <br />
          <br />

          <section id="work" className="inner">

            <div className="card-wrapper featured research service-design user-experience digital-ui digital-ux interaction-design">
              <a href="/work/service-design-mercedes">
                <div className="image">
                  <img src={project1} alt="" />
                </div>
                <div className="card-info">
                  <div className="text">
                  Lowered the barrier to electric car ownership by improving 
                    the accessibility and usability of the charging infrastructure 
                    for Mercedes-Benz and Smart car owners.
                  </div>
                  <br />
                  <p className="maisontags-card">Research, Service Design, User Experience, Digital UX/UI</p>
                </div>
              </a>
            </div>

            <div className="card-wrapper featured ai-enabled research service-design user-experience digital-ui digital-ux interaction-design ux-strategy branding">
              <a href="/work/service-design-acrole">
                <div className="image">
                  <img src={project2} alt="" />
                </div>
                <div className="card-info">
                  <div className="text">
                  Redesigned the field-ops personnel
                     role by leveraging AI-enabled 
                     digital tools and optimizing offline & online 
                     processes to reduce costs and improve 
                     service quality.
                  </div>
                  <br />
                  <p className="maisontags-card">AI-enabled, Research, UX & Business Strategy, Service Design, User Experience, Digital UX/UI</p>
                </div>
              </a>
            </div>



            {/* <div className="card-wrapper research service-design user-experience digital-ui digital-ux interaction-design ux-strategy branding">
              <a href="/work/service-design-muvisi">
                <div className="image">
                  <img src={project3} alt="" />
                </div>
                <div className="card-info">
                  <div className="text">
                  A smart mobility service that makes it stress-free 
                    & convenient to find on-street parking spots in the city.
                  </div>
                  <br />
                  <p className="maisontags-card">Research, Service Design, User Experience, Digital UX/UI, UX Strategy, Brand Identity</p>
                </div>
              </a>
            </div> */}

            

            <div className="card-wrapper featured research service-design user-experience digital-ui digital-ux interaction-design ux-strategy branding">
              <a href="/work/service-design-blueprint">
                <div className="image">
                  <img src={project4} alt="" />
                </div>
                <div className="card-info">
                  <div className="text">
                  Facilitating a Service Blueprint Workshop to provide holistic visibility 
                    across teams, leading to a pivot in the organization's 
                    strategy, business goals, and focus areas.
                  </div>
                  <br />
                  <p className="maisontags-card">Facilitation, Research, Service Design, UX & Business Strategy</p>
                </div>
              </a>
            </div>


            <div className="card-wrapper digital-ui digital-ux branding interaction-design">
              <a href="/work/service-design-keycrime">
                <div className="image">
                  <img src={project5} alt="" />
                </div>
                <div className="card-info">
                  <div className="text">
                    A crime analysis software that uses Machine Learning &
                    Artificial Intelligence for predictive policing.
                  </div>
                  <br />
                  <p className="maisontags-card">Research, Information Architecture, Digital UX/UI, Brand Idendity</p>
                  
                </div>
              </a>
            </div>

            <div className="card-wrapper featured ai-enabled research service-design user-experience digital-ui digital-ux interaction-design ux-strategy branding">
              <a href="/work/service-design-postop">
                <div className="image">
                  <img src={project6} alt="" />
                </div>
                <div className="card-info">
                  <div className="text">
                  Improved post surgery outcomes by enabling patients to 
                    manage their recovery independently with confidence through 
                    remote monitoring by clinical teams.
                  </div>
                  <br />
                  <p className="maisontags-card">AI-enabled, Research, UX Strategy, Service Design, User Experience, Digital UX/UI</p>
                </div>
              </a>
            </div>

            


            <div className="card-wrapper research ux-strategy user-experience interaction-design digital-ui digital-ux product-design branding">
              <a href="/work/service-design-tecne">
                <div className="image">
                  <img src={project7} alt="" />
                </div>
                <div className="card-info">
                  <div className="text">
                  Redesigned combat training with a sensor-fitted Underglove 
                    and an App to provide athletes and trainers with precise, 
                    data-informed feedback for improvement.
                  </div>
                  <br />
                  <p className="maisontags-card">Research, UX Strategy, User Experience, Product Design, Digital UX/UI, Brand Identity</p>
                  
                </div>
              </a>
            </div>

            <div className="card-wrapper featured research service-design user-experience digital-ui digital-ux interaction-design ux-strategy">
              <a href="/work/service-design-progression">
                <div className="image">
                  <img src={project8} alt="" />
                </div>
                <div className="card-info">
                  <div className="text">
                  Improved credit recovery timelines, user motivation, 
                    and loyalty by designing a comprehensive 'Progression 
                    System' with diverse engagement strategies.
                  </div>
                  <br />
                  <p className="maisontags-card">Research, UX Strategy, User Experience, Digital UX/UI</p>
                </div>
              </a>
            </div>


            <div className="card-wrapper service-design user-experience ux-strategy">
              <a href="/work/service-design-hive-part-1">
                <div className="image">
                  <img src={project9} alt="" />
                </div>
                <div className="card-info">
                  <div className="text">
                    Unique experiences that bring people together over home
                    cooked meals hosted by local chefs in neighbourhoods.
                  </div>
                  <br />
                  <p className="maisontags-card">Service Design, User Experience, UX & Business Strategyn</p>
                  
                </div>
              </a>
            </div>

            <div className="card-wrapper research interaction-design user-experience digital-ui digital-ux">
              <a href="/work/service-design-bsmart">
                <div className="image">
                  <img src={project10} alt="" />
                </div>
                <div className="card-info">
                  <div className="text">
                  Bringing a human-centric approach to privacy control 
                  & data transparency of IoT products at home.
                  </div>
                  <br />
                  <p className="maisontags-card">Research, User Experience, Digital UX/UI</p>
                  
                </div>
              </a>
            </div>

            {/* <div className="card-wrapper product-design">
              <a href="/work/product-design-3-furniture-pieces">
                <div className="image">
                  <img src={project9} alt="" />
                </div>
                <div className="card-info">
                  <div className="text">
                    Contemporary & bespoke furniture designs that are a perfect
                    addition to their individual setting.
                  </div>
                  <br />
                  <p className="maisontags-card">Product Design</p>
                  
                </div>
              </a>
            </div> */}


            <div className="card-wrapper product-design user-experience">
              <a href="/work/product-design-household-dustbins">
                <div className="image">
                  <img src={project11} alt="" />
                </div>
                <div className="card-info">
                  <div className="text">
                    A family of household dustbins whose aesthetic appearance
                    can be customised to user's preferences.
                  </div>
                  <br />
                  <p className="maisontags-card">Product Design, User Experience</p>
                  
                </div>
              </a>
            </div>

            {/* <div className="card-wrapper service-design ux-strategy user-experience">
              <a href="/work/service-design-kin">
                <div className="image">
                  <img src={project6} alt="" />
                </div>
                <div className="card-info">
                  <div className="text">
                    A service for Type II Diabetes management
                    that enables trusted relationships through empathic
                    encounters.
                  </div>
                  <br />
                  <p className="maisontags-card">Service Design, User Experience, UX Strategy</p>
                  
                </div>
              </a>
            </div> */}

            {/* <div className="card-wrapper research interaction-design user-experience">
              <a href="/work/service-design-tech-no-mad">
                <div className="image">
                  <img src={project1} alt="" />
                </div>
                <div className="card-info">
                  <div className="text">
                    An installation that explores how comfortable users
                     feel in sharing personal data in different situations.
                  </div>
                  <br />
                  <p className="maisontags-card">Research, User Experience, Interaction Design</p>
                  
                </div>
              </a>
            </div> */}


            {/* <div className="card-wrapper product-design user-experience ux-strategy">
              <a href="/work/product-design-hop-philips">
                <div className="image">
                  <img src={project8} alt="" />
                </div>
                <div className="card-info">
                  <div className="text">
                    An interactive & playful street element to promote awareness
                    and values of the brand Philips Lighting.
                  </div>
                  <br />
                  <p className="maisontags-card">Product Design, User Experience, UX Strategy</p>
                </div>
              </a>
            </div> */}


            


            <div className="card-wrapper user-experience digital-ux digital-ui interaction-design">
              <a href="/work/service-design-hive-part-2">
                <div className="image">
                  <img src={project12} alt="" />
                </div>
                <div className="card-info">
                  {/* <div className="title">hive (part 2).</div> */}
                  <div className="text">
                    A mobile application to find & book food experiences; and a
                    backend dashboard for hosts to manage their listings.
                  </div>
                  <br />
                  <p className="maisontags-card">Digital UX/UI, User Experience</p>
                  {/* <div className="tags">
                    <ul>
                      <li>DIGITAL UI/UX</li>
                      <li>UX STRATEGY</li>
                      <li>USER EXPERIENCE</li>
                      <li>INTERACTION DESIGN</li>
                    </ul>
                  </div> */}
                </div>
              </a>
            </div>

            

            {/* <div className="card-wrapper service-design user-experience ux-strategy business-design">
              <a href="/work/service-design-levico-terme">
                <div className="image">
                  <img src={project7} alt="" />
                </div>
                <div className="card-info">
                  <div className="text">
                    Using gamification techniques to provide a holistic &
                    customised sports service performance & rehabilitation.
                  </div>
                  <br />
                  <p className="maisontags-card">Service Design, User Experience, UX Strategy, Business Design</p>
                </div>
              </a>
            </div> */}

            

            {/* <div className="card-wrapper service-design user-experience ux-strategy">
              <a href="/work/service-design-save">
                <div className="image">
                  <img src={project5} alt="" />
                </div>
                <div className="card-info">
                  <div className="text">
                    An IoT service for smart payment & expense tracking that
                    offers personalised & contextual financial experience.
                  </div>
                  <br />
                  <p className="maisontags-card">Service Design, User Experience, UX Strategy</p>
                </div>
              </a>
            </div> */}


            {/* <div className="card-wrapper product-design user-experience">
              <a href="/work/product-design-persono-coffee-table">
                <div className="image">
                  <img src={project12} alt="" />
                </div>
                <div className="card-info">
                  <div className="text">
                    Coffee table for the living room at home that allows the
                    ultimate flexibility of usage for various contexts.
                  </div>
                  <br />
                  <p className="maisontags-card">Product Design, User Experience</p>
                </div>
              </a>
            </div> */}
            
            {/* <div className="card-wrapper product-design">
              <a href="/work/product-design-mumbai-street-furniture">
                <div className="image">
                  <img src={project11} alt="" />
                </div>
                <div className="card-info">
                  <div className="text">
                    A family of street elements for the city of Mumbai, India,
                    reflecting its environment, usage needs & culture.
                  </div>
                  <br />
                  <p className="maisontags-card">Product Design</p>
                </div>
              </a>
            </div> */}

            {/* <div className="show-more-link">+ SHOW MORE</div> */}
          </section>

          <section id="footertopcta" className="inner">
            <p className="text arrow-front">
            <a href="/aboutme">now that you have seen some of my works,
               know a bit about me</a>
            </p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Work
